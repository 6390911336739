import React, { Component } from 'react';
import Phone from "../images/phone.svg"

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
            },
            errors: {
                firstName: 'First Name is required!',
                lastName: 'Last Name is required!',
                email: 'Valid Email is required!',
                phone: 'Valid Phone is required!',
            },
            hasErrors: false,
        };
    }

    handleChange = (event) => {
        event.preventDefault();
        const validEmailRegex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/)
        const validPhoneRegex = new RegExp(/((\(\d{3}\)?)|(\d{3}))([\s-./]?)(\d{3})([\s-./]?)(\d{4})/)
        const { name, value } = event.target;
        let errors = this.state.errors;
        let fields = this.state.fields;

        switch (name) {
            case 'firstName':
                errors.firstName = value.length > 0 && '';
                fields.firstName = value;
                break;
            case 'lastName':
                errors.lastName = value.length > 0 && '';
                fields.lastName = value;
                break;
            case 'email':
                errors.email = (validEmailRegex.test(value) ? '' : 'Valid Email is required!');
                fields.email = value;
                break;
            case 'phone':
                errors.phone = (validPhoneRegex.test(value) ? '' : 'Valid Phone is required!');
                fields.phone = value;
                break;
            case 'message':
                fields.message = value;
                break;
            default:
                break;
        }

        this.setState({ errors, fields, [name]: value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm(this.state.errors)) {
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "Schedule a Consultation", ...this.state.fields })
            })
                .then(() => {
                    window.dataLayer !== "undefined" && window.dataLayer.push({"event":"contactForm"});
                    window.location.pathname = "/thanks"
                })
                .catch(error => alert(error));
        } else {
            this.setState({ hasErrors: true })
        }
    }

    render() {
        const { hasErrors, errors } = this.state;
        const PhoneCta = () => <><img style={{ width: 35, marginRight: 9, paddingBottom: 5 }} src={Phone} alt='phone icon' /></>
        return (
            <div className="jumbotron jumbotron-fluid with-shadow">

                <div className='d-flex flex-column align-items-center' id="consultation">
                    <p className="is-purple font-weight-bold m-0 pb-1">Request a FREE Consultation</p>
                    <a className="is-purple font-weight-bold font-size-36 pb-1" href="tel:+18449344863"><PhoneCta />844.934.4863</a>
                    <form name="Schedule a Consultation" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/success" onSubmit={this.handleSubmit} >
                        <input type="hidden" name="Schedule a Consultation" value="contact" />
                        <div className="container">
                            <div className='row'>
                                <div className="col-sm">
                                    <div className="d-flex flex-column mb-2 is-purple">
                                        <label htmlFor="firstName">First Name</label>
                                        <input id="name" className="p-2" placeholder="e.g. John" type='text' name='firstName' onChange={this.handleChange} noValidate />
                                        {errors.firstName.length > 0 && hasErrors &&
                                            <span className='error'>{errors.firstName}</span>}
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="d-flex flex-column mb-2 is-purple">
                                        <label htmlFor="lastName">Last Name</label>
                                        <input className="p-2" placeholder="e.g. Doe" type='text' name='lastName' onChange={this.handleChange} noValidate />
                                        {errors.lastName.length > 0 && hasErrors &&
                                            <span className='error'>{errors.lastName}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm">
                                    <div className="d-flex flex-column mb-2 is-purple">
                                        <label htmlFor="email">Email</label>
                                        <input className="p-2" placeholder="e.g. john@email.com" type='email' name='email' onChange={this.handleChange} noValidate />
                                        {errors.email.length > 0 && hasErrors && errors.email &&
                                            <span className='error'>{errors.email}</span>}
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="d-flex flex-column mb-2 is-purple">
                                        <label htmlFor="phone">Phone</label>
                                        <input type='phone' name='phone' className="p-2" placeholder="e.g 1231231234" onChange={this.handleChange} noValidate />
                                        {errors.phone.length > 0 && hasErrors && errors.phone && 
                                            <span className='error'>{errors.phone}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm">
                                    <div className="d-flex flex-column mb-2 is-purple">
                                        <label htmlFor="text">Additional Information</label>
                                        <textarea type='text' className="p-2" placeholder="Your message" name='message' onChange={this.handleChange} noValidate />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column mb-2 mt-3">
                                <button className="submit">Request a Consultation</button>
                            </div>
                            <p className="is-purple p-2 text-center font-size-14 info-privacy">Your information is 100% confidential and will never be shared with anyone</p>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

export default ContactForm